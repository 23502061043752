import styled, { css } from 'styled-components'

export const Navigation = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    opacity: 1;
    transition: opacity 0.5s;
    ${({ isOpen }) =>
      !isOpen &&
      css`
        opacity: 0;
        transform: scale(0);
      `};
  }
  width: 100%;
  @media screen and (min-width: 992px) {
    margin: auto;
    height: initial;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media screen and (min-width: 1200px) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  @media screen and (min-width: 1400px) {
    width: 85%;
  }
`

export const FWBusinessButton = styled.a`
  transition: transform 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    transform: translate3d(0, -0.25rem, 0);
  }
  img {
    height: 43px;
  }
`

export const Logo = styled.a`
  width: 60%;
  text-align: center;
  @media screen and (min-width: 992px) {
    margin-bottom: 1rem;
    width: initial;
  }
  @media screen and (min-width: 1200px) {
    margin-bottom: initial;
    width: initial;
  }
`

export const Header = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #fbfbfb;
  padding: 0 10px;
`

export const NavListWrapper = styled.div`
  height: 100vh;
  position: fixed;
  width: 80%;
  top: 0;
  left: 0;
  background-color: #fbfbfb;
  z-index: 1250;
  ${({ isOpen }) =>
    !isOpen &&
    css`
      transform: translateX(-100%)};
    `};
  transition: transform 0.5s;
  @media screen and (min-width: 992px) {
    position: initial;
    width: initial;
    height: 100%;
    transform: initial;
    flex-direction: row;
    align-items: center;
  }
`

export const NavList = styled.ul`
  list-style: none;
  padding: 4.4rem;
  height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 0;

  @media screen and (min-width: 992px) {
    height: 100%;
    padding: 0;
    flex-direction: row;
    align-items: center;
  }
`

export const NavItem = styled.li`
  display: flex;
  align-items: center;
  position: relative;
  @media screen and (min-width: 992px) {
    margin: 0 2.4rem;
  }
`

export const NavLink = styled.a`
  font-size: 1rem;
  letter-spacing: 1px;
  color: #3e4982;
  display: block;
  line-height: 1.4;
  position: relative;
  &:after {
    height: 2px;
    background: #3e4982;
    content: '';
    width: 100%;
    position: absolute;
    ${({ isActive }) =>
      !isActive &&
      css`
        transform: scaleX(0);
      `};
    transition: transform 100ms ease-in;
    left: 0;
    margin-top: 2rem;
  }
  &:hover,
  &:focus {
    color: #3e4982;
    outline: none;
    text-decoration: none;
    &:after {
      transform: scaleX(1);
    }
  }
`

export const Toggle = styled.div`
  display: block;
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
  z-index: 1500;
  img {
    width: 18px;
    height: 18px;
  }
  @media screen and (min-width: 992px) {
    display: none;
  }
`
