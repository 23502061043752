import styled from 'styled-components'
import { Select } from 'antd'

export const AntSelect = styled(Select)`
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-top-color: transparent !important;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-radius: 0;
    }
  }

  &:not(.ant-select-disabled):hover {
    .ant-select-selector {
      border-bottom-color: ${(props) => props.theme.PRIMARY_COLOR};
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-bottom-color: ${(props) => props.theme.PRIMARY_COLOR};
      box-shadow: 0 1px 0 0 ${(props) => props.theme.PRIMARY_COLOR};
    }
  }
`
