import Head from 'next/head'

const Meta = ({ title, description, path = '', image }) => {
  return (
    <Head>
      <meta charSet="utf-8" />
      <link rel="icon" href="/favicon.ico" />
      <meta name="theme-color" content="#000000" key={`page-${path}`} />
      <meta name="description" content={description} key={`page-${path}`} />

      <meta itemprop="name" content={title} key={`page-${path}`} />
      <meta itemprop="description" content={description} key={`page-${path}`} />
      <meta itemprop="image" content={image} key={`page-${path}`} />

      <meta property="og:url" content={`https://flavorwiki.com/${path}`} key={`page-${path}`} />
      <meta property="og:type" content="website" key={`page-${path}`} />
      <meta property="og:title" content={title} key={`page-${path}`} />
      <meta property="og:description" content={description} key={`page-${path}`} />
      <meta property="og:image" content={image} key={`page-${path}`} />

      <meta name="twitter:card" content="summary_large_image" key={`page-${path}`} />
      <meta name="twitter:title" content={title} key={`page-${path}`} />
      <meta name="twitter:description" content={description} key={`page-${path}`} />
      <meta name="twitter:image" content={image} key={`page-${path}`} />

      <title>{title}</title>
    </Head>
  )
}

export default Meta
