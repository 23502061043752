export const LANGUAGES = [
  {
    value: 'en',
    isoCode: 'en-US',
    flagCode: 'us',
    i18nCode: 'en',
    flags: ['us', 'uk', 'sg', 'ie']
  },
  {
    value: 'ar',
    isoCode: 'ar',
    flagCode: 'arabic',
    i18nCode: 'ar',
    flags: [
      'dz',
      'bh',
      'km',
      'dj',
      'iq',
      'jo',
      'kw',
      'lb',
      'ly',
      'mr',
      'ma',
      'om',
      'ps',
      'qa',
      'sa',
      'so',
      'ss',
      'sy',
      'tn',
      'ae',
      'ye'
    ]
  },
  {
    value: 'ar_EG',
    isoCode: 'eg',
    flagCode: 'eg',
    i18nCode: 'ar_EG',
    flags: ['eg']
  },
  {
    value: 'cn',
    isoCode: 'zh-CN',
    flagCode: 'cn',
    i18nCode: 'zh_CN',
    flags: ['cn']
  },
  {
    value: 'nl_NL',
    isoCode: 'nl-NL',
    flagCode: 'nl',
    i18nCode: 'nl_NL',
    flags: ['nl']
  },
  {
    value: 'fil',
    isoCode: 'fil',
    flagCode: 'ph',
    i18nCode: 'fil',
    flags: ['ph']
  },
  {
    value: 'fr',
    isoCode: 'fr-FR',
    flagCode: 'fr',
    i18nCode: 'fr',
    flags: ['fr']
  },
  {
    value: 'de',
    isoCode: 'de-DE',
    flagCode: 'de',
    i18nCode: 'de',
    flags: ['de']
  },
  {
    value: 'he_IL',
    isoCode: 'he-IL',
    flagCode: 'il',
    i18nCode: 'he_IL',
    flags: ['il']
  },
  {
    value: 'id',
    isoCode: 'id-ID',
    flagCode: 'id',
    i18nCode: 'id',
    flags: ['id']
  },
  {
    value: 'it',
    isoCode: 'it-IT',
    flagCode: 'it',
    i18nCode: 'it_IT',
    flags: ['it']
  },
  {
    value: 'ja',
    isoCode: 'ja-JP',
    flagCode: 'jp',
    i18nCode: 'ja',
    flags: ['jp']
  },
  {
    value: 'kr',
    isoCode: 'ko-KR',
    flagCode: 'kr',
    i18nCode: 'ko',
    flags: ['kr']
  },
  {
    value: 'ms_MY',
    isoCode: 'ms-MY',
    flagCode: 'my',
    i18nCode: 'ms_MY',
    flags: ['my']
  },
  {
    value: 'pl',
    label: 'Polski',
    isoCode: 'pl-PL',
    flagCode: 'pl',
    i18nCode: 'pl',
    flags: ['pl']
  },
  {
    value: 'pt',
    isoCode: 'pt-PT',
    flagCode: 'pt',
    i18nCode: 'pt',
    flags: ['pt']
  },
  {
    value: 'pt_BR',
    isoCode: 'pt-BR',
    flagCode: 'br',
    i18nCode: 'pt_BR',
    flags: ['br']
  },
  {
    value: 'ro',
    isoCode: 'ro',
    flagCode: 'ro',
    i18nCode: 'ro',
    flags: ['ro']
  },
  {
    value: 'ru',
    isoCode: 'ru-RU',
    flagCode: 'ru',
    i18nCode: 'ru_RU',
    flags: ['ru']
  },
  {
    value: 'es',
    isoCode: 'es-ES',
    flagCode: 'es',
    i18nCode: 'es',
    flags: ['es']
  },
  {
    value: 'es_419',
    isoCode: 'es-ES',
    flagCode: 'es_419',
    i18nCode: 'es_419',
    flags: ['ar', 'cl', 'co', 'bo', 'pr', 've', 'pa', 'ec', 'py', 'hn', 'mx', 'cr', 'cu', 'do']
  },
  {
    value: 'se',
    isoCode: 'sv-SE',
    flagCode: 'se',
    i18nCode: 'sv',
    flags: ['se']
  },
  {
    value: 'th',
    isoCode: 'th-TH',
    flagCode: 'th',
    i18nCode: 'th',
    flags: ['th']
  },
  {
    value: 'tr',
    isoCode: 'tr-TR',
    flagCode: 'tr',
    i18nCode: 'tr',
    flags: ['tr']
  },
  {
    value: 'ur',
    isoCode: 'ur-PK',
    flagCode: 'pk',
    i18nCode: 'ur_PK',
    flags: ['pk']
  },
  {
    value: 'am',
    isoCode: 'am',
    flagCode: 'am',
    i18nCode: 'am',
    flags: ['am']
  },
  {
    value: 'vi',
    isoCode: 'vi-VN',
    flagCode: 'vn',
    i18nCode: 'vi',
    flags: ['vi']
  }
]
