import { useTranslation } from 'react-i18next'
import { BUSINESS_SITE, FB_REVIEW } from 'utils/constants'
import {
  Container,
  Footer,
  FooterContent,
  Flavorwiki,
  List,
  ListItem,
  FWBusinessButton
} from './styles'

const FooterComponent = () => {
  const { t } = useTranslation()

  return (
    <Footer>
      <Container>
        <FooterContent>
          <Flavorwiki src="/images/flavorwiki-logo-white.svg" />
          <List>
            <ListItem>{t('components.footer.copyright')}</ListItem>
            <ListItem>
              <a href={process.env.NEXT_PUBLIC_MAILTO_FLAVORWIKI}>{t('components.footer.email')}</a>
            </ListItem>
            <ListItem>
              <a target="_blank" href="/terms">
                {t('components.footer.terms')}
              </a>
            </ListItem>
            <ListItem>
              <a target="_blank" href="/privacy-policy">
                {t('components.footer.privacyPolicy')}
              </a>
            </ListItem>
            <ListItem>
              <a target="_blank" href={process.env.NEXT_PUBLIC_LINKEDIN}>
                <img src="/icons/linkedin.svg" alt="linkedin" />
              </a>
              <a target="_blank" href={FB_REVIEW}>
                <img src="/icons/facebook.svg" alt="facebook" />
              </a>
              <a target="_blank" href={process.env.NEXT_PUBLIC_TWITTER}>
                <img src="/icons/twitter.svg" alt="twitter" />
              </a>
              <a target="_blank" href="https://www.instagram.com/flavorwiki">
                <img src="/icons/instagram.svg" alt="instagram" />
              </a>
            </ListItem>
            <FWBusinessButton target="_blank" href={BUSINESS_SITE}>
              <img src="/images/flavorwiki-business.png" alt="FlavorWiki for Business" />
            </FWBusinessButton>
          </List>
        </FooterContent>
      </Container>
    </Footer>
  )
}

export default FooterComponent
