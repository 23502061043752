import styled from 'styled-components'
import ReactCountryFlag from 'react-country-flag'
import FWSelect from 'components/UIKits/Select'

export const Select = styled(FWSelect)`
  min-width: 12rem;
`

export const CountryFlag = styled(ReactCountryFlag)`
  width: auto;
  height: 1rem;
  margin-right: 0.5rem;
`

export const CustomImage = styled.img`
  margin-right: 0.5rem;
  width: 1em;
  height: 1em;
`
