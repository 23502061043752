import styled from 'styled-components'
import Flex from 'styled-flex-component'
import { space, textAlign, fontWeight, layout } from 'styled-system'

export const Div = styled.div`
  ${space}
  ${textAlign}
  ${fontWeight}
  ${layout}
`

export const List = styled(Flex)`
  max-width: 1174px;
  width: 100%;
  margin: auto;
  justify-content: space-between;
  @media screen and (min-width: 1400px) {
    width: 80%;
  }
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`

export const Container = styled.div`
  max-width: 1188px;
  margin: auto;
  padding-top: 2.5rem;
`

export const BasicSection = styled.section`
  padding: 5rem 1.5rem;
`

export const BasicSectionFooter = styled.footer`
  margin: 3.75rem auto auto;
`

export const ResponsiveTextSize = styled.div`
  font-size: 10px;
  @media screen and (min-width: 400px) {
    font-size: 12px;
  }
  @media screen and (min-width: 992px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1400px) {
    font-size: 16px;
  }
`

export const BasicSectionTitle = styled.h2`
  font-size: 2.5rem;
  word-break: break-word;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2;
  letter-spacing: 0.5rem;
  margin-bottom: 2.5rem;
  white-space: break-spaces;
  @media screen and (max-width: 600px) {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`

export const BasicSectionSubTitle = styled.h3`
  font-size: 1.75rem;
  text-align: center;
  line-height: 1.2;
  letter-spacing: 0.5rem;
`

export const BasicSectionDescription = styled.div`
  font-size: 1.75rem;
  font-weight: 300;
  text-align: center;
`
