import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select, CountryFlag, CustomImage } from './styles'
import { LANGUAGES } from 'utils/constants'

const SelectLanguage = ({ value, onChange }) => {
  const { t } = useTranslation()
  return (
    <Select value={value} onChange={onChange}>
      {LANGUAGES.map(language => (
        <Select.Option key={language.value} value={language.value}>
          {language.flagCode !== 'es_419' && language.flagCode !== 'arabic' && (
            <CountryFlag countryCode={language.flagCode} svg />
          )}

          {(language.flagCode === 'es_419' || language.flagCode === 'arabic') && (
            <CustomImage src='/images/latinamericaflag.svg' alt='latin-america' />
          )}

          {t(`languages.${language.i18nCode}`)}
        </Select.Option>
      ))}
    </Select>
  )
}

export default SelectLanguage
