import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { setLanguage } from 'redux/language/languageSlice'
import i18nInstance from 'utils/internationalization/i18n'
import SelectLanguage from 'components/SelectLanguage'
import { BUSINESS_SITE } from 'utils/constants'
import { Alert } from 'antd'
import {
  Header,
  Navigation,
  Logo,
  NavList,
  NavLink,
  NavItem,
  Toggle,
  NavListWrapper,
  FWBusinessButton
} from './styles'

const Navbar = ({ currentPage, route, language }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const router = useRouter()
  const query = router.query
  const currentLanguage = useSelector((state) => state.language.currentLanguage)

  const updateRoute = (value) => {
    if (route === '/') {
      const link = query.source ? `/${value}?source=${query.source}` : `/${value}`
      router.replace(link)
    } else {
      const link = query.source ? `/${route}?source=${query.source}` : `/${route}`
      router.replace(link)
    }
  }

  const changeLanguage = (value) => {
    dispatch(setLanguage(value))
    i18nInstance.changeLanguage(value)
    updateRoute(value)
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const isActive = (path) => {
    if (!path && currentPage === '/') {
      return true
    }
    return currentPage.includes(path)
  }

  useEffect(() => {
    i18nInstance.changeLanguage(language)
  }, [])

  return (
    <>
      <Header>
        <Alert message={t('inChinaWarning')} type='warning' showIcon closable /> 
        <Navigation isOpen={isOpen}>
          {isOpen && (
            <Toggle onClick={toggleOpen}>
              <img alt="close" src="/icons/close.png" />
            </Toggle>
          )}
          {!isOpen && (
            <Toggle onClick={toggleOpen}>
              <img alt="menu" src="/icons/menu.png" />
            </Toggle>
          )}
          <Logo href={`/${currentLanguage}`}>
            <img src="/images/flavorwiki-logo.svg" width={210} height={40} />
          </Logo>
          <NavListWrapper isOpen={isOpen}>
            <NavList>
              <NavItem>
                <NavLink isActive={isActive('faq')} href="/faq">
                  {t('components.navbar.faq')}
                </NavLink>
              </NavItem>
              {/* <NavItem>
              <NavLink isActive={isActive('blog')} href="/blog">
                {t('components.navbar.blog')}
              </NavLink>
            </NavItem> */}
              <NavItem>
                <NavLink
                  href={`${process.env.NEXT_PUBLIC_TASTER_APP}/sign-in?lang=${
                    currentLanguage || 'en'
                  }`}>
                  {t('components.navbar.tasterLogin')}
                </NavLink>
              </NavItem>
              <NavItem>
                <SelectLanguage value={language} onChange={changeLanguage} />
                {/* <LanguageSelect onChange={changeLanguage} value={language}>
                <option value="en">English</option>
                <option value="de">German</option>
                <option value="pt_BR">Portuguese (Brazil)</option>
                <option value="pl">Poland</option>
                <option value="es">Spanish</option>
                <option value="sv">Swedish</option>
              </LanguageSelect> */}
              </NavItem>
              <NavItem>
                <FWBusinessButton target="_blank" href={BUSINESS_SITE}>
                  <img src="/images/fw-business-cta-top.png" alt="FlavorWiki for Business" />
                </FWBusinessButton>
              </NavItem>
            </NavList>
          </NavListWrapper>
        </Navigation>
      </Header>
    </>
  )
}

export default Navbar
