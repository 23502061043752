import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  @media screen and (min-width: 1400px) {
    width: 85%;
  }
  margin: auto;
`

export const Footer = styled.footer`
  background: linear-gradient(to right, rgba(58, 60, 67, 1) 0%, rgba(122, 132, 179, 1) 100%);
  text-align: center;
  padding: 1.5rem;
`

export const FooterContent = styled.div`
  @media screen and (min-width: 1200px) {
    display: flex;
    align-items: center;
  }
`

export const Flavorwiki = styled.img`
  width: auto;
  height: 2rem;

  @media screen and (max-width: 1200px) {
    margin-bottom: 1rem;
  }
`

export const FWBusinessButton = styled.a`
  height: 72px;
  transition: transform 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    transform: translate3d(0, -0.25rem, 0);
  }
  img {
    height: 60px;
  }
`

export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  margin-left: auto;
  margin-bottom: 0;
`

export const ListItem = styled.li`
  color: #ffffff;
  font-size: 0.75rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  @media screen and (min-width: 1200px) {
    padding-left: 1rem;

    &:not(:last-child) {
      padding-right: 1rem;
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 0.5rem 1rem;

    &:last-child {
      width: 100%;
    }
  }

  a {
    color: #ffffff;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    transition: transform 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    &:hover {
      transform: translate3d(0, -0.25rem, 0);
    }
    width: auto;
    height: 2rem;

    @media screen and (min-width: 1200px) {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    @media screen and (max-width: 1200px) {
      margin-left: 0.75rem;
      margin-right: 0.75rem;
    }
  }
`
